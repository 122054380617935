import { createGlobalStyle } from "styled-components";
import FuturaMediumWoff2 from "../static/fonts/futura/Futura-Medium.woff2";
import FuturaMediumWoff from "../static/fonts/futura/Futura-Medium.woff";

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Futura';
      src: local('Futura'), local('Futura'),
      url(${FuturaMediumWoff2}) format('woff2'),
      url(${FuturaMediumWoff}) format('woff');
      font-style: normal;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }
  body {
    background-color: #231f20;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='90' height='28' viewBox='0 0 90 28'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%0a1912' fill-opacity='0.13' fill-rule='nonzero'%3E%3Cpath d='M75.6,13.5L60.5,0H29.5L14.4,13.5H0v1h14.4L29.5,28h30.9l15.2-13.5H90v-1H75.6z M58.8,28H31.1L15.5,14L30.1,1
      h29.8l14.6,13L58.8,28z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    /* overflow: hidden; */
  }
  @-moz-document url-prefix() {
    body .with-ff-fix {
      font-weight: lighter !important;
    }
  }
`;
export default GlobalStyle;
